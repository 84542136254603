header {
  padding-top: 7rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

/* CTA */
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* Socials */

.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
  z-index: 1;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

.header__socials a {
  font-size: 1.2rem;
}

/* Me */

.me {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 10rem;
}

.header__img {
  position: relative;
  width: 17rem;
}

.me::before {
  content: "";
  background: linear-gradient(var(--color-primary), transparent);
  width: 22rem;
  height: 30rem;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  border-radius: 12rem 12rem 0 0;
}

/* Scroll Down */

.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

/* Media Queries for MD Devices */

@media screen and (max-width: 1024px) {
}

/* Media Queries for SM Devices */

@media screen and (max-width: 600px) {
  .header__socials,
  .scroll__down {
    display: none;
  }
}
